import { formatPhone } from './format'
import _escape from 'lodash/escape'

export function convertUrlsToLinks(inputText) {
  // Converts any https urls to <a> tags. Note that string is escaped first to sanitize user content.
  try {
    let text = _escape(inputText)
    const words = text.split(/\s/g) // Split on any whitespace chr
    const uniqueUrls = [
      ...new Set(words.filter(w => w.indexOf('https://') === 0))
    ]
    uniqueUrls.forEach(url => {
      const link = `<a onclick="event.stopPropagation()" class="hyperlink" href="${url}" target="_blank">${url}</a>`
      text = text.replaceAll(url, link)
    })
    return text
  } catch (e) {
    return _escape(inputText)
  }
}

export function searchParentElements(el, test) {
  // Returns true if the current element or any parent element meets
  // the condition specified in "test"
  if (test(el)) {
    return true
  }
  return el.parentElement ? searchParentElements(el.parentElement, test) : false
}

export function formatPhoneHtml(string) {
  /*
    Takes a phone number string and returns it as a series of html spans
    designed to separate out the number (e.g. 0470 500 600).

    The reason to use this "span" method instead of inserting spaces is
    that the user can copy/paste the value without unwanted spaces.  
  */
  if (string) {
    const parts = formatPhone(string).split(' ')
    return parts
      .map(
        (part, idx) =>
          `<span ${
            idx < parts.length - 1 ? `style="padding-right:0.2em"` : ''
          }>${part}</span>`
      )
      .join('')
  }
  return string
}

export function scrollRowIntoView($list, $row) {
  /*
    Scrolls $row into view if necessary.

    $list - Reference to scrollable html "list" element
    $row -  Reference to html "row" element that must be in view
  */
  if (
    $row.offsetTop + $row.offsetHeight >
    $list.scrollTop + $list.offsetHeight
  ) {
    $list.scrollTop = $row.offsetTop + $row.offsetHeight - $list.offsetHeight
  } else if ($row.offsetTop < $list.scrollTop) {
    $list.scrollTop = $row.offsetTop
  }
}
