<template>
  <div v-if="state.show" :class="`page ${state.fade ? 'fade' : ''}`">
    <div class="content">
      <div class="logo">
        <img :src="require('@/assets/img/sonic-swirl.svg')" />
        <img :src="require('@/assets/img/sonic-text.svg')" />
      </div>

      <q-linear-progress
        :value="state.isLoading"
        :animation-speed="2000"
        class="q-mt-md"
        color="white"
        rounded
        track-color="indigo-3"
      />
      <div style="text-align: right">
        <div class="tagline">
          <div>Provisioning Management by</div>
          <img :src="require(`@/assets/img/swoop-logo-white.svg`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  z-index: 9999999;
  background-color: var(--primary);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1.5s;

  &.fade {
    opacity: 0;
  }

  .content {
    width: 60vw;
    max-width: 500px;
    color: white;

    .logo {
      display: flex;
      align-items: center;
      img:first-of-type {
        width: 34%;
      }
      img:last-of-type {
        width: 66%;
        padding-left: 15px;
        margin-top: -15px;
      }
    }

    .tagline {
      margin-top: 5px;
      font-style: italic;
      font-weight: 300;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;

      img {
        width: 64px;
        padding-left: 5px;
      }
    }
  }
}
</style>

<script setup>
import { reactive, onMounted } from 'vue'

const skipPaths = ['/appointment', '/logout']

onMounted(() => {
  if (skipPaths.indexOf(location.pathname) === -1) {
    state.show = true
    setTimeout(() => (state.isLoading = 1), 0) // Start bar animation
    setTimeout(() => (state.fade = true), 1000) // Fade after short interval
    setTimeout(() => (state.show = false), 2500) // Hide after fade
  }
})

const state = reactive({
  show: false,
  fade: false,
  isLoading: 0
})
</script>
