/*
  This provides data transformations to make order data as consistent and
  easily accessible as possible. They directly transform the api response 
  data for tasks and orders.
  
  Transformations include:
  
      1. Flattening some data structures to make things simple for the UI
      2. Adding in useful calculated values (prefixed by '_')
      3. Adjusting values to make them consistent (e.g. brand values, status values etc)
      4. Merging in additional data (such as NBN comments)

  This approach means the data is cleaned up in one place and UI templates are
  kept free of these data complexities. The data consistency allows UI components
  to continue working across a range of different order types. For example Sonic 
  has a single BrandIcon component which can be used anywhere because all brand data
  has been transformed to a consistent set of brand values.
*/

import { getUsers } from '@/store/ui-state'
import getStatusColor from '@/utils/statusColors'
import { differenceInBusinessDays, addBusinessDays } from 'date-fns'
import { convertUrlsToLinks } from '@/utils/html'
import { joinAddressParts } from '@/utils/address'

const taskPriority = [
  { task: 'raise.support.ticket', days: 1, priority: 1 },
  { task: 'existing.service.check', days: 1, priority: 1 },
  { task: 'review.fibre.issue', days: 1, priority: 1 },
  { task: 'autofill.manual.handling', days: 2, priority: 1 },
  { task: 'ship.hardware', days: 1, priority: 1 },
  { task: 'followup.shipping', days: 2, priority: 2 },
  { task: 'handle.api.issue', days: 1, priority: 2 },
  { task: 'manual.appointment', days: 2, priority: 2 },
  { task: 'followup.reschedule', days: 3, priority: 3 },
  { task: 'review.rejected.order', days: 1, priority: 1 },
  { task: 'review.rejected.order.disconnect', days: 1, priority: 1 },
  { task: 'raise.ticket.billing', days: 1, priority: 1 },
  { task: 'disconnect.existing.copper.service', days: 7, priority: 3 },
  { task: 'review.notes', days: 3, priority: 2 },
  { task: 'manually.cancel.billing', days: 3, priority: 3 }
]

export function fixBrand(brand) {
  /*
    We have a bunch of inconsistent brand values coming through which we 
    correct so that the UI has a single set to deal with. Accepted values:

    Anycast
    DCSI
    DCSI Business
    Moose
    Moose Mobile
    NodeOne
    SWOOP
  */
  if (!brand || isMatch(brand, 'Unknown')) {
    return '[EMPTY]'
  }
  if (isMatch('node_one', brand) || isMatch('nodeone', brand)) {
    return 'NodeOne'
  }
  if (isMatch('swoop', brand)) {
    return 'SWOOP'
  }
  if (isMatch('moose', brand)) {
    return 'Moose'
  }
  if (isMatch(brand, 'dcsi')) {
    return 'DCSI'
  }
  if (isMatch(brand, 'DCSI - Business') || isMatch(brand, 'dcsi business')) {
    return 'DCSI Business'
  }
  return brand
}

function isMatch(val1, val2) {
  // Case insensitive match
  return RegExp(`^${val2}$`, 'gi').test(val1)
}

function setSalesChannel(record) {
  // Logic according to Carolyn's specification
  const brand = record.brand
  if (brand === 'Anycast') {
    record._salesChannel = 'Wholesale'
  } else if (
    brand === 'NodeOne' ||
    brand.indexOf('DCSI') === 0 ||
    brand.indexOf('Moose') === 0
  ) {
    record._salesChannel = 'Direct'
  }
}

function setSourceSystem(record) {
  // Logic according to Carolyn's specification
  const brand = record.brand
  const product = record._product || ''
  const isTargetBrand =
    brand === 'NodeOne' ||
    brand.indexOf('DCSI') === 0 ||
    brand.indexOf('Moose') === 0

  /*
    As requested by Carolyn, we need to check product/plan name for the presence
    of an address, e.g.
      
    "Business Internet - UNIT 503, LOT 21, 35 ASTOR TCE, SPRING HILL QLD 4000 (LEVEL 5, THE ASTOR) - 50/20Mbps - 1 months"

    To make this simple we just check for the presence of a comma and a dash, as it
    appears that product names without addresses do not have both of these characters. 
    This is a temporary measure until the backend can provide this info.
  */
  const isAddressInPlanName =
    product.indexOf(',') > 0 && product.indexOf('-') > 0

  if (isTargetBrand && !isAddressInPlanName) {
    record._sourceSystem = 'Website'
  } else if (product === 'Traffic Class 4') {
    record._sourceSystem = 'Channel Portal'
  } else if (isTargetBrand && isAddressInPlanName) {
    record._sourceSystem = 'Salesforce'
  }
}

function setOrderWorkflowStep(record) {
  /*
    For orders we want to store the current workflow step (activity) in _taskType | _taskName | _taskId.
      * If childActivityInstances contains a user task, store that.
      * If childActivityInstances contains a record with ':' in its id, store that. e.g. 'review.rejected.order:fcf01691-e7d0-11ed-83f6-0242ac120018'
      * Otherwise store the first available childActivityInstance
  */

  const instances =
    record.childActivityInstances ||
    record.workflowActivity?.childWorkflowActivity ||
    [] // New order list format uses latter

  let activity =
    instances.find(r => r.activityType === 'userTask') ||
    instances.find(r => r.id && r.id.indexOf(':') > 0) ||
    instances[0] ||
    {}
  if (activity.childActivityInstances?.length === 1) {
    // If activity has a child activity, use it instead of the parent (as it will be more specific)
    activity = activity.childActivityInstances[0]
  }
  if (activity.childWorkflowActivity?.length === 1) {
    // If activity has a child activity (new order list naming), use it instead of the parent (as it will be more specific)
    activity = activity.childWorkflowActivity[0]
  }
  record._taskType = activity.activityType
  record._taskName = activity.name || activity.activityName || '' // New order list format uses latter
  record._taskId = activity.activityId || activity.activityID // New order list format uses latter
}

function setStatusValues(record) {
  record._isInFlight =
    record._isTask || record._taskName !== 'Order Finished Processing'
  record._statusText = record.nbnStatus || 'Pending'
  if (record.nbnStatus === 'Complete') {
    record._statusText = record._isInFlight ? 'Finalising' : 'Complete'
  } else if (record.nbnSubStatus) {
    record._statusText += ` - ${record.nbnSubStatus}`
  }

  record._isTaskAvailable =
    record._taskType === 'userTask' && record._isInFlight
  record._isTerminated =
    ['Rejected', 'Cancelled', 'Withdrawn'].indexOf(record.nbnStatus) >= 0 &&
    !record._isInFlight
  record._isComplete = record.nbnStatus === 'Complete' && !record._isInFlight

  const statusColor = getStatusColor(
    record._statusText === 'Finalising' ? 'Finalising' : record.nbnStatus
  )
  record._statusColor = statusColor.color
  record._statusBgColor = statusColor.bgcolor
}

function getShippingAddress(record) {
  const shipping = record.hardware?.shippingAddress || {}
  record._shippingAddress = shipping.shippingAddress1
    ? joinAddressParts(
        [shipping.shippingAddress1, shipping.shippingAddress2],
        [
          shipping.shippingSuburb,
          shipping.shippingState,
          shipping.shippingPostcode
        ]
      )
    : ''

  if (shipping.shippingContactName || shipping.shippingContactNumber) {
    record._shippingContact = `${shipping.shippingContactName || '[NO NAME]'}${
      shipping.shippingContactNumber
        ? ` (${shipping.shippingContactNumber})`
        : ''
    }`
  } else {
    record._shippingContact = ''
  }
}

function moveVariablesToRoot(record) {
  // Move each variable array value to the root
  const vars = record.variables
  if (!vars) {
    return
  }
  if (record._isTask) {
    Object.keys(vars).forEach(key => {
      record[key] = vars[key].value
    })
  } else {
    vars.forEach(v => {
      record[v.key] = v.value
    })
  }
  delete record.variables
}

function setDueDays(record) {
  if (!record._isTask) {
    return
  }

  if (record.due) {
    record._dueDate = new Date(record.due)
    record._priority = record.priority
  } else {
    setLegacyDueDateAndPriority(record)
  }
  record._followUpDate = record.followUp ? new Date(record.followUp) : null
  record._dueDays = differenceInBusinessDays(
    record._followUpDate || record._dueDate,
    new Date()
  )
}

function setLegacyDueDateAndPriority(record) {
  /*
    'due' and 'priority' values are now being added into tasks. However at the time
    of writing many tasks don't have these values, so we have to keep the old system
    (of calculating these values based on task type) as a fallback.
  */
  let tp = taskPriority.find(tp => tp.task === record._taskId) || {
    days: 3,
    priority: 3
  }
  record._priority = tp.priority
  let isDisconnect = record._taskId === 'disconnect.existing.copper.service'
  record._dueDate = addBusinessDays(
    new Date(record.created),
    isDisconnect ? 5 : tp.days
  )
}

function setIsSyncOrderAvailable(record) {
  // Set _isSyncOrderAvailable if record has a Simple client, order and service.
  if (record.orderExternalReferences) {
    record._isSyncOrderAvailable =
      record.orderExternalReferences.filter(r => {
        return (
          r.externalSystem === 'simple' &&
          ['service', 'order'].indexOf(r.entity) >= 0
        )
      }).length === 2
  }
}

function setPrimaryContact(record) {
  record.contacts = record.contacts || []

  // If order has no contacts, add account contact...
  if (!record.contacts.length && record.account) {
    record.contacts.push({
      contactType: 'account_contact',
      name: record.account.name,
      email: record.account.email,
      phone: record.account.phone
    })
  }

  // Map to various backend formats to consistent frontend format
  record.contacts = record.contacts.map(c => {
    return {
      contactType: c.contactType,
      name: c.contactName || `${c.firstName} ${c.lastName}`,
      email: c.email,
      phone: c.phoneNumber || c.phone,
      notes: c.notes
    }
  })
}

function setProvisioningNoteUser(task) {
  if (task.provisioningNotes) {
    task.provisioningNotes = convertUrlsToLinks(task.provisioningNotes)
    const extRef = task.orderExternalReferences.find(
      r =>
        r.externalSystem.toLowerCase() === 'salesforce' &&
        r.entity === 'opportunity_owner'
    )
    task._provisioningNotesUser =
      extRef?.externalSystemId || '<Unknown sales rep>'
  }
}

function mergeNbnComments(record, nbnComments = []) {
  record.history = record.history || []
  nbnComments.forEach(c =>
    record.history.push({
      content: c.text,
      createdDate: c.date,
      eventType: 'nbn_comment',
      loggedInUserId: `${c.author || '<unknown>'} (${c.role || 'NBN'})`
    })
  )
  // Sort order history as newest first
  try {
    record.history.sort((b, a) => a.createdDate.localeCompare(b.createdDate))

    // We ignore 'nbnStatusChange' events because they are effectively duplicates of 'callbackFromNBN' events
    record.history = record.history.filter(
      evt => evt.eventType !== 'nbnStatusChange'
    )
  } catch (e) {
    //
  }
}

export default function (record, isNewOrderListFormat, nbnComments) {
  if (record.task) {
    // Combine task and order_data content into root. Makes structure more consistent with
    // orders, allowing UI components to be reused between Tasks and Orders.
    record = {
      ...record.order_data,
      ...record.task,
      variables: record.variables
    }

    delete record.task
    delete record.order_data

    const orderRef = record.Tc4NbnServicePayload.externalId
    record._isV1Task = orderRef.substring(0, 3) === 'SNC'
    if (record._isV1Task) {
      record._orderId = Number(orderRef.replace('SNC', '')) // Get id number out of order ref
    }

    record._isTask = true
    record._taskType = 'userTask'
    record._taskName = record.name
    record._taskId = record.taskDefinitionKey
    if (record._taskId.indexOf('review.withdraw.order') === 0) {
      // Actual taskDefinitionKey has a incremental number after it which we must remove
      record._taskId = 'review.withdraw.order'
    }
    if (!record.assignee) {
      record.assignee = null
    }
    setProvisioningNoteUser(record)
  } else if (isNewOrderListFormat) {
    record._isOrder = true
    record.nbnOrderId = record.nbnOrderReference
    record.nbnSubStatus = record.nbnSubstatus
    record.startDateTime = record.createdDate
    record.id = record.processInstanceId
    setOrderWorkflowStep(record)
  } else {
    // Record is an order...

    // Combine process_instance and order_data content into root. Makes structure more consistent with
    // tasks, allowing UI components to be reused between Tasks and Orders.
    record = { ...record.order_data, ...record.process_instance }

    delete record.process_instance
    delete record.order_data

    record._isOrder = true
    setOrderWorkflowStep(record)
  }

  if (!isNewOrderListFormat) {
    moveVariablesToRoot(record)
    setDueDays(record)

    // Adds "_connection" object so that we have a consistent
    // location for connection information.
    record._connection =
      record.nbnConnectPayload || record.Tc4NbnServicePayload || {}
    delete record.nbnConnectPayload
    delete record.Tc4NbnServicePayload

    // Flatten data by moving some nested values to root fields (prefixed with '_')
    let charges = record.billing?.charges || []
    let product = charges.find(
      p => p.timing.indexOf('recurring') > -1 && p.product != 'promo'
    )
    record._product = product ? product.description : ''
    getShippingAddress(record)
    setPrimaryContact(record)
  }

  record._customer = record.customerName || record.account?.name || ''
  record._phone = record.customerPhone || record.account?.phone || ''
  record._email = record.customerEmail || record.account?.email || ''

  record._customerType = record.orderType || record.account?.customerType || ''
  record._locid =
    record.nbnLocId?.String || record._connection?.relatedPlaceId || ''
  record._serviceabilityClass =
    record.serviceabilityClass ||
    record._connection?.serviceabilityClass ||
    record.autofill?.serviceabilityClass

  setStatusValues(record)
  setIsSyncOrderAvailable(record)

  record._orderRef =
    record.sonicOrderReference || record._connection?.externalId
  record.brand = fixBrand(record.brand)
  setSalesChannel(record)
  setSourceSystem(record)
  mergeNbnComments(record, nbnComments)

  if (record.assignee !== undefined) {
    let assignee = getUsers().find(u => u.value === record.assignee)
    record._assigneeName = assignee ? assignee.label : record.assignee
  }
  return record
}
