import router from '../router'
import { Amplify } from 'aws-amplify'
import {
  fetchAuthSession,
  signInWithRedirect,
  getCurrentUser,
  signOut
} from '@aws-amplify/auth'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
      userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
      loginWith: {
        oauth: {
          domain: process.env.VUE_APP_COGNITO_APP_DOMAIN,
          scopes: ['openid email'],
          redirectSignIn: [process.env.VUE_APP_COGNITO_LOGIN_REDIRECT],
          redirectSignOut: [process.env.VUE_APP_COGNITO_LOGOUT_REDIRECT],
          responseType: 'token'
        },
        username: 'true'
      }
    }
  }
})

const isUserSignedIn = async () => {
  try {
    return !!(await getCurrentUser())
  } catch (e) {
    return false
  }
}

export default {
  async isUserSignedIn() {
    return await isUserSignedIn()
  },

  async login(currentPath) {
    // Stores current path so we can navigate back here after authentication
    localStorage.setItem('originalPath', currentPath)
    await signInWithRedirect()
  },

  async logout() {
    await signOut()
  },

  async getAccessToken() {
    const session = await fetchAuthSession()
    return session.tokens.accessToken
  },

  async authenticated() {
    /*
      At this point Cognito has redirected to "/login/oauth2/code/cognito" after
      successful authentication. Now we just need to navigate back to the page the
      user was originally trying to access.
    */
    const redirectPath = localStorage.getItem('originalPath')
    localStorage.removeItem('originalPath')
    router.push(redirectPath || '/')
  }
}
