import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import auth from '../auth'
import authApi from '../api/auth'
import { setUiFlags, getUser, setUser, setUsers } from '@/store/ui-state'
import orderApi from '@/api/orders'

const openPaths = ['/appointment']

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

const router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,
  history: createWebHistory()
})

router.beforeEach(async (to, from, next) => {
  const isAppPage = openPaths.indexOf(to.path) === -1
  switch (to.path) {
    case '/login/oauth2/code/cognito':
      auth.authenticated()
      next()
      break

    case '/logout':
      auth.logout()
      next()
      break

    default:
      if (isAppPage) {
        await prepareApp(to, next)
      } else {
        next()
      }
  }
})

async function prepareApp(to, next) {
  // Login if necessary...
  if (!(await auth.isUserSignedIn())) {
    await auth.login(to.href)
    next(false)
    return
  }

  // Load prerequisite app data if necessary...
  if (!getUser()) {
    setUser(await authApi.getUserInfo())
    try {
      setUsers(await authApi.getUsers())
    } catch (e) {
      setUsers([{ value: null, label: 'ERROR LOADING USERS' }])
    }
    try {
      setUiFlags(await orderApi.getUiFlags())
    } catch (e) {
      // If flags request fails, just continue (without blocking the whole UI)...
    }
  }

  // Continue to page...
  next()
}

export default router
