<!-- 
  This template displays a modal confirmation message based on any
  configuration set in src/store/ui-state. If configuration is null
  then no message will be displayed.

  Params:

    - text (string): Primary message text
    - detail (string): Optional secondary message text
    - detailHtml (string): Optional secondary message html
    - okText (string): Optional text for the confirm button (defaults to "OK")
    - cancelText (string): Optional cancel button text (defaults to "Cancel"). If set to null or empty, no cancel button is displayed.
    - icon (string): Optional icon name (from Material Icons) to use for the main icon. Otherwise a question mark icon will be used if the confirmation has confirm & cancel buttons, and otherwise an info icon will be used.
    - color (string): Optional color for the primary button

  EXAMPLE USAGE:

    import { confirm } from '@/store/ui-state'
    const isDelete = await confirm({
      icon: "delete",
      text: "Delete record?",
      detail: "This will delete the customer record.",
      okText: "Delete"
    })

-->

<template>
  <q-dialog v-model="showDialog" @hide="onCancel">
    <q-card v-if="!!params" class="confirm">
      <q-card-section class="row">
        <q-icon :color="iconColor" :name="icon" size="60px" />

        <div class="content col">
          <div class="message text-weight-bold">{{ params.text }}</div>
          <div class="detail" v-if="params.detail">{{ params.detail }}</div>
          <div
            class="detail"
            v-if="params.detailHtml"
            v-html="params.detailHtml"
          ></div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          v-if="cancelButtonText"
          flat
          :label="cancelButtonText"
          color="primary"
          @click="onCancel"
        />
        <q-btn
          v-if="confirmButtonText"
          unelevated
          :label="confirmButtonText"
          :color="buttonColor"
          @click="onConfirm"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { getConfirm, clearConfirm } from '@/store/ui-state'
import { computed } from 'vue'

const params = computed(() => getConfirm())
const showDialog = computed({
  get() {
    return !!getConfirm()
  },
  set() {
    clearConfirm()
  }
})

const confirmButtonText = computed(() => params.value.okText || 'OK')

const cancelButtonText = computed(() => {
  if (params.value.cancelText === undefined) {
    return 'Cancel'
  }
  return params.value.cancelText || ''
})

const icon = computed(() => {
  let isQuestion = !!cancelButtonText.value
  return params.value.icon || (isQuestion ? 'help_outline' : 'info_outline')
})

const buttonColor = computed(() => params.value.color || 'primary')

const iconColor = computed(() => params.value.iconColor || 'primary')

function onConfirm() {
  params.value.onConfirm()
}

function onCancel() {
  if (params.value) {
    if (cancelButtonText.value) {
      params.value.onCancel() // Only trigger cancel if cancel button available
    } else {
      params.value.onConfirm()
    }
  }
}
</script>

<style scoped>
.confirm {
  min-width: 300px;
}
.content {
  margin: 1em 1em 0 1em;
  flex: 1;
}
.message {
  font-size: 16px;
}
.detail {
  margin-top: 0.5em;
}
</style>
