import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';
import './quasar-imports'
import './styles/app.css'
import './components/fields/index.css'

const app = createApp(App)
app.use(router)
app.use(Quasar, {
  plugins: {
    Notify
  }
})
app.mount('#app')
