export default function (status) {
  // Returns background and foreground color to be used for order status chip

  status = status ? status.toLowerCase().replace(/\W/g, '') : 'pending'

  const mobileRunning = {
    color: 'blue-10',
    bgcolor: 'blue-1'
  }

  const mobileError = {
    color: 'white',
    bgcolor: 'negative'
  }

  const colors = {
    acknowledged: {
      color: 'light-blue-10',
      bgcolor: 'light-blue-2'
    },
    inprogress: {
      color: 'white',
      bgcolor: 'blue'
    },
    complete: {
      color: 'white',
      bgcolor: 'positive'
    },
    held: {
      color: 'white',
      bgcolor: 'orange'
    },
    rejected: {
      color: 'white',
      bgcolor: 'negative'
    },
    cancelled: {
      color: 'red-10',
      bgcolor: 'red-1'
    },
    withdrawn: {
      color: 'white',
      bgcolor: 'grey'
    },
    pending: {
      color: 'blue-10',
      bgcolor: 'blue-1'
    },
    finalising: {
      color: 'green-9',
      bgcolor: 'green-1'
    },
    pendingresubmit: {
      color: 'blue-10',
      bgcolor: 'cyan-1'
    },
    unknown: {
      color: 'white',
      bgcolor: 'grey'
    },

    // Mobile statuses

    mooseconnected: {
      color: 'white',
      bgcolor: 'positive'
    },
    readytosendmfa: mobileRunning,
    readytobeconnectedmfa: mobileRunning,
    readyforportsubmit: mobileRunning,
    portsubmitted: mobileRunning,
    portcomplete: mobileRunning,
    portmismatch: mobileError,
    readytobeconnectedmfanoreply: mobileError,
    portsubmitcustomererror: mobileError,
    portstuck: mobileError,
    portreject: mobileError,
    waitingforsimallocation: {
      color: 'deep-orange-10',
      bgcolor: 'deep-orange-1'
    },
    waitingfordelivery: {
      color: 'deep-orange-10',
      bgcolor: 'deep-orange-1'
    },
    manualoverride: {
      color: 'white',
      bgcolor: 'blue'
    }
  }
  return colors[status] || colors['unknown']
}
