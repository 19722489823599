export const formatPhone = val => {
  /* 
    Formats to a standard human readable phone format (following
    guidelines for mobile vs landline formatting) e.g.
    
      "+61 (0) 470 400 500"   -->   "0470 400 500"
      "61470400500"   -->   "0470 400 500"
      "61299501850"   -->   "02 9950 1850"
      "0470400500"    -->   "0470 400 500"
      "0299501850"    -->   "02 9950 1850"
  */

  try {
    // Strip all non-digits and remove 61 or 0 prefix
    val = val.replace(/\D/g, '').replace(/^61/g, '').replace(/^0/g, '')
    const landlinePattern = '# #### ####'
    const mobilePattern = '### ### ###'
    const isMobile = val[0] === '4'
    const pattern = isMobile ? mobilePattern : landlinePattern
    let returnVal = ''
    let idx = 0
    pattern.split('').forEach(chr => {
      if (chr === '#') {
        returnVal += val[idx] || ''
        idx++
      } else {
        returnVal += chr
      }
    })
    return '0' + returnVal
  } catch (e) {
    return val
  }
}
