/*
  Provides essential configuration for apiHelper in the form of:
    * Default request settings
    * Hooks to integrate with the rest of the app
*/

import auth from '../auth'
import {
  showSpinner as _showSpinner,
  hideSpinner as _hideSpinner,
  setError
} from '@/store/ui-state'

export default {
  /* ----------------------- DEFAULTS ----------------------- */

  // Default api service, e.g. "https://myapi.com.au"
  defaultApi: process.env.VUE_APP_API_ROOT,

  // true: showSpinner() hook is called by default for every request unless "spinner: false" is present on the request.
  // false: showSpinner() hook is only called when "spinner: true" is present on the request.
  spinner: true,

  /* ---------------------- AUTH HOOKS ---------------------- */

  // Hook for getting the access token to be used in the authorization header (unless overridden in the request)
  async getAccessToken() {
    return await auth.getAccessToken()
  },

  // Hook for establishing a valid session (unless request has auth:false)
  async isUserSignedIn() {
    return await auth.isUserSignedIn()
  },

  /* ----------------------- UI HOOKS ----------------------- */

  // Hook for showing an app-level loading spinner
  showSpinner() {
    _showSpinner()
  },

  // Hook for hiding the above spinner
  hideSpinner() {
    _hideSpinner()
  },

  /* 
    Hook for displaying an error dialog. params is an object containing all 
    necessary information for building an error dialog:
      - response (Object): Response object from apiHelper
      - headers (Object): Headers object from apiHelper
      - payload (Object): Payload object from apiHelper
      - transformationError (Object): If error is a coding error inside a data transformation and not an actual api error, this object is populated from apiHelper.
  */
  showError(params) {
    setError(params)
  }
}
