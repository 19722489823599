import API from './apiHelper'
import _startCase from 'lodash/startCase'
import _camelCase from 'lodash/camelCase'

function getNameFromUsername(username) {
  // e.g. 'swoop_adam.wells@swoop.com.au' > 'Adam Wells'
  username = username.replace('swoop_', '').split('@')[0].replace('.', ' ')
  return _startCase(_camelCase(username))
}

export default {
  getUserInfo() {
    return API.get(
      `https://${process.env.VUE_APP_COGNITO_APP_DOMAIN}/oauth2/userInfo`,
      {
        spinner: false
      }
    )
  },

  getUsers() {
    return API.get(
      `${process.env.VUE_APP_INFINITY}/v0/identity/group?group=provisioning`,
      {
        spinner: false,
        transform(response) {
          // Transforms users into label/value pairs for use with Quasar q-select...
          let users = response.data.map(u => {
            return {
              value: u.Username,
              label: getNameFromUsername(u.Username)
            }
          })
          users.sort((a, b) =>
            a.label.toLowerCase().localeCompare(b.label.toLowerCase())
          )
          users.unshift({ value: null, label: 'UNASSIGNED' })
          return users
        }
      }
    )
  }
}
