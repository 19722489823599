/*
  Provides a simple way of making API requests (using fetch api)

  EXAMPLE USAGE: -----------------------------------
  
      import API from '@/api/apiHelper'

      const data = await API.get(`/customer/1234`)

      API.post(`/customer`, { data })
  
  MAIN FEATURES: -----------------------------------
  
    - Default auth headers
    - Default url root (so paths can be relative)
    - Default spinner display during in flight requests
    - Default error notification
    - Simple data transformation
    - Simple mock data
  
  REQUEST TYPES: -----------------------------------
  
    API.get(url, options)
    API.post(url, options)
    API.put(url, options)
    API.patch(url, options)
    API.delete(url, options)
  
  REQUEST PARAMETERS: ------------------------------

    url <String> (required)     -  e.g. "/customers/<id>". Will be prefixed with api root unless endpoint begins with an HTTP protocol
    options <Object> (optional) -  
      - data <Object>:  Payload data. For multipart/form-data, use formData (below).
      - formData <Object>:  If submitting multipart/form-data (e.g. file uploads), specify that form data here. 
      - downloadFile <String>:  If specified, response is treated as a file and saved as the name specified (e.g. "filename.csv").
      - spinner <Boolean>:  Flag to specify if app spinner (see "showSpinner" hook in config file) should appear during the request. Default is "true".
      - loadingRef <Object>:  Template ref for reporting loading state. If specified, this ref's value will be set to true for the duration of the request. NOTE: State defined by "reactive" cannot be passed in, only refs.
      - headers <Object>:  Custom headers.
      - auth <Boolean>: If false, default "Authorization" header is removed and no "signed in" check is made (for use with open paths). Default is "true"
      - mock <Array | Object>:  Mock data. When specified, this data will be returned instead of making a real request (see "mockResponseTime" for adjusting the delay).
      - transform <Function>:  Data transformation function. Receives response data and must return transformed response data.
      - plaintext <Boolean>:  If true, both payload and response are treated as text instead of json. Default is false.
      - silent <Boolean>:  If true, api errors will not automatically be displayed as on-screen notifications.
      - mockResponseTime <Number>:  Specifies number of milliseconds delay for mock data to be returned (when used with 'mock' parameter), overriding the default mockResponseTime.
      - cacheSeconds <Number>: Specifies number of seconds to cache results for (GET requests only). For safety and simplicity ALL cached results are invalidated after ANY POST, PUT or DELETE requests. 
      - notifyError <Function>: When an api returns error messages in 200 response data, you can use notifyError() to display them. The function takes the response and should return any error message to be displayed.  
*/

import config from './apiHelper.config'
import router from '../router'
import Notify from 'quasar/src/plugins/notify/Notify.js';
import _upperFirst from 'lodash/upperFirst'
import _startCase from 'lodash/startCase'

let incompleteRequests = 0 // Tracks number of requests currently running in order to know when to hide the spinner (for requests that are using automatic spinner).
let cache = []

export default {
  get(endpoint, params = {}) {
    return request('get', endpoint, params)
  },
  post(endpoint, params = {}) {
    return request('post', endpoint, params)
  },
  put(endpoint, params = {}) {
    return request('put', endpoint, params)
  },
  patch(endpoint, params = {}) {
    return request('patch', endpoint, params)
  },
  delete(endpoint, params = {}) {
    return request('delete', endpoint, params)
  }
}

// Local helper functions ---------------------------------------------

async function request(
  method,
  endpoint,
  {
    params,
    data,
    spinner = config.spinner,
    loadingRef,
    headers,
    auth: isAuth = true,
    mock,
    transform,
    plaintext,
    formData,
    downloadFile,
    silent,
    mockResponseTime,
    cacheSeconds,
    notifyError
  }
) {
  invalidateCacheIfNecessary(method, endpoint)
  setLoadingIndicators(spinner, loadingRef)

  // Return mock data if endpoint is setup to do so...
  let mockData = getMockData(
    mock,
    transform,
    { url: endpoint, method: method },
    endpoint
  )
  if (mockData) {
    return new Promise(resolve =>
      setTimeout(
        () => {
          clearLoadingIndicators(spinner, loadingRef)
          resolve(mockData)
        },
        typeof mockResponseTime === 'number' ? mockResponseTime : 500
      )
    )
  }

  if (cacheSeconds && method === 'get') {
    const cachedResponse = cache.find(c => c.endpoint === endpoint)
    if (cachedResponse) {
      return new Promise(resolve => {
        clearLoadingIndicators(spinner, loadingRef)
        resolve(cachedResponse.data)
      })
    }
  }

  // Otherwise make real api request...
  let response = {}
  response = await fetchRequest(
    method,
    endpoint,
    params,
    data,
    headers,
    isAuth,
    plaintext,
    formData,
    downloadFile
  )

  clearLoadingIndicators(spinner, loadingRef)

  if (!response.ok) {
    if (!silent) {
      displayError({
        response,
        headers: await getHeaders(headers, isAuth, formData),
        payload: data || formData,
        endpoint
      })
    }
    throw response.error
  }

  if (response.data) {
    displayCustomError(response, notifyError)

    try {
      const responseData = transform ? transform(response.data) : response.data
      if (cacheSeconds && method === 'get') {
        // When a real request is made, add response to cache if cacheSeconds param present...
        cache.push({
          endpoint,
          data: responseData,
          expiry: new Date().getTime() + cacheSeconds * 1000
        })
      }
      return responseData
    } catch (e) {
      displayError({ response, transformationError: e, endpoint }) // No 'silent' check here - as data transformation errors should always be reported.
    }
  }
}

async function fetchRequest(
  method,
  endpoint,
  params,
  data,
  headers,
  isAuth,
  plaintext,
  formData,
  downloadFile
) {
  const requestUrl = resolveEndpoint(endpoint, params)
  const requestBody = {
    method: method.toUpperCase(),
    body: formData || (plaintext ? data : JSON.stringify(data)),
    headers: new Headers(await getHeaders(headers, isAuth, formData))
  }
  const request = new Request(requestUrl, requestBody)
  try {
    const response = await fetch(request)

    await processResponse(response, downloadFile)

    if (!response.ok) {
      response.error = response.data
      response.method = method.toUpperCase() // Add method for error reporting
    }
    return response
  } catch (e) {
    /* 
      Fetch completely failed with no response object (endpoint did not exist or was
      not accessible). So we construct our own error response which has enough info for the 
      error notification.
    */
    return { error: e.message, method: method.toUpperCase(), url: requestUrl }
  }
}

async function processResponse(response, downloadFile) {
  if (downloadFile) {
    // Trigger file download via temporary link...
    const blob = await response.blob()
    var url = window.URL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.href = url
    tempLink.download = downloadFile
    document.body.appendChild(tempLink)
    tempLink.click()
    tempLink.remove()
  } else {
    response.data = await response.text()
    try {
      response.data = JSON.parse(response.data) // Parse as JSON if possible
    } catch (e) {
      // If not, this must have been a text response - so leave as text.
    }
  }
}

async function getHeaders(headers = {}, isAuth, formData) {
  if (headers['Content-Type'] === undefined && !formData) {
    /*
      NOTE: We do not (and must not) set Content-Type when using form data (file uploads).
      The browser will set this automatically.
    */
    headers['Content-Type'] = 'application/json'
  }
  if (isAuth) {
    if (!(await config.isUserSignedIn())) {
      /*
        If session expired, refresh the page (router.go) so that router logic
        can handle the re-auth.
      */
      router.go()
      return
    }
    headers['Authorization'] = 'Bearer ' + (await config.getAccessToken())
  }
  return headers
}

function resolveEndpoint(endpoint, urlParams) {
  /*
    Endpoints can be full or relative. If relative, they will automatically
    be prefixed with config.defaultApi. The opening slash is optional for
    relative endpoints.
  */
  if (
    endpoint.indexOf('http') === 0 ||
    endpoint.indexOf('https') === 0 ||
    endpoint.indexOf('//') === 0
  ) {
    return endpoint
  }
  if (endpoint.indexOf('/') !== 0) {
    endpoint = `/${endpoint}`
  }
  if (urlParams) {
    // If urlParams object specified, serialize to url params...
    endpoint =
      endpoint +
      (endpoint.includes('?') ? '&' : '?') +
      Object.entries(urlParams)
        .map(p => `${p[0]}=${p[1]}`)
        .join('&')
  }
  return config.defaultApi + endpoint
}

function displayError(params) {
  // Builds "toast" notification
  const actions = [
    {
      label: 'Details',
      color: 'yellow',
      handler: () => config.showError(params)
    }
  ]
  Notify.create({
    type: 'negative',
    message: getNotificationHtml(params),
    html: true,
    timeout: 12000,
    actions
  })
}

function getNotificationHtml(params) {
  // Builds compact, user-friendly error details for toast notification
  const error = params.response.error
  if (error === 'Failed to fetch') {
    return `<b>URL INACCESSIBLE: </b>${params.endpoint}`
  }
  if (params.transformationError) {
    return `<b>DATA TRANSFORMATION ERROR: </b>${params.endpoint}`
  }
  if (typeof error === 'object') {
    // If error is json, convert key/values to formatted text...
    const rows = []
    Object.entries(error).forEach(keyValue => {
      // Don't include keys without a value...
      if (keyValue[1]) {
        rows.push(
          `<div><span class='text-weight-light q-pr-sm'>${_startCase(keyValue[0])}:</span><span class='text-weight-medium'>${_upperFirst(keyValue[1])}</span></div>`
        )
      }
    })
    return rows.join('')
  }
  return `<b>REQUEST FAILED:</b> ${error}`
}

function setLoadingIndicators(spinner, loadingRef) {
  if (spinner) {
    incompleteRequests++
    config.showSpinner()
  }
  if (loadingRef && loadingRef.value !== undefined) {
    loadingRef.value = true
  }
}

function clearLoadingIndicators(spinner, loadingRef) {
  /*
    Upon request completion any app spinner or loadingRef is cleared.

    SPINNER NOTE: Unless turned off, each request triggers the app spinner until complete.
    However each request can't just hide the spinner when complete because there could be 
    other incomplete requests in progress. So a counter is used to keep track of incomplete requests,
    and the spinner is only hidden after all requests have completed (incompleteRequests = 0)

    setTimeout() is used to ensure the current command stack completes before hiding the spinner.
    This allows async requests to be made one after the other with a single continous spinner (as
    the next request has already started before attempting to hide the spinner).
  */
  if (spinner) {
    setTimeout(() => {
      if (incompleteRequests) {
        incompleteRequests--
      }
      if (!incompleteRequests) {
        config.hideSpinner()
      }
    }, 0)
  }
  if (loadingRef?.value === true) {
    loadingRef.value = false
  }
}

function getMockData(mockData, transform, request, endpoint) {
  /*
      mockData <array | object> - Optional mock data. 
      transform <function> - Optional api data transformation function
  */
  if (!mockData) {
    return null
  }
  try {
    if (transform) {
      mockData = transform(mockData)
    }
    return JSON.parse(JSON.stringify(mockData)) // Return clone of mock data, otherwise it can be mutated.
  } catch (e) {
    displayError({ response: request, transformationError: e, endpoint })
    return null
  }
}

function invalidateCacheIfNecessary(method, endpoint) {
  if (method !== 'get') {
    cache = [] // Invalidate all cached results when any data is modified
  } else {
    const idx = cache.findIndex(c => c.endpoint === endpoint)
    if (idx >= 0 && new Date() > cache[idx].expiry) {
      cache.splice(idx, 1)
    }
  }
}

function displayCustomError(response, notifyError) {
  /*
    If notifyError() function has been provided, call function and pass in the
    response. If the function returns a message, display it in a notification.
  */
  if (typeof notifyError === 'function') {
    try {
      const message = notifyError(response.data)
      if (message) {
        Notify.create({
          type: 'negative',
          message: `<b>${message}</b>`,
          html: true,
          timeout: 10000
        })
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`REQUEST ERROR IN notifyError() FOR ${response.url}:`, e)
    }
  }
}
