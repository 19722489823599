import { getHomeRoute } from '@/store/ui-state'

export default [
  {
    //
    path: '/login/oauth2/code/cognito',
    component: () => null
  },

  {
    path: '/logout',
    component: () => import('@/views/LogoutPage')
  },

  {
    path: '/',
    component: () => import('@/layouts/SecureLayout'),
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        children: [
          {
            /*
              If you navigate to "/home" without specifying a subroute (e.g. "/home/tasks"),
              the last visited subroute will be used.
            */
            path: '',
            redirect: () => getHomeRoute() || '/home/tasks'
          },
          {
            path: 'tasks',
            component: () => import('@/views/home/TaskCharts')
          },
          {
            path: 'nbn-connect',
            component: () => import('@/views/home/OrderCharts')
          },
          {
            path: 'nbn-disconnect',
            component: () => import('@/views/home/DisconnectionCharts')
          }
        ]
      },
      {
        path: 'tasks',
        children: [
          {
            path: '',
            component: () => import('@/views/tasks/TaskList')
          },
          {
            path: ':id',
            component: () => import('@/views/tasks/TaskDetails')
          }
        ]
      },

      {
        path: 'orders',
        children: [
          {
            path: '',
            redirect: () => '/orders/nbn/connect'
          },
          {
            path: 'nbn/connect',
            component: () => import('@/views/orders/nbn/connect/OrderList')
          },
          {
            path: 'nbn/connect/:id',
            component: () => import('@/views/orders/nbn/connect/OrderDetails')
          },
          {
            path: 'nbn/disconnect',
            component: () => import('@/views/orders/nbn/disconnect/OrderList')
          },
          {
            path: 'nbn/disconnect/:id',
            component: () =>
              import('@/views/orders/nbn/disconnect/OrderDetails')
          },
          {
            path: 'mobile/connect',
            component: () => import('@/views/orders/mobile/connect/OrderList')
          },
          {
            path: 'mobile/connect/:id',
            component: () =>
              import('@/views/orders/mobile/connect/OrderDetails')
          },
          {
            path: 'mobile/mismatch',
            component: () => import('@/views/orders/mobile/mismatch/OrderList')
          }
        ]
      },

      {
        path: 'tools',
        children: [
          {
            path: '',
            redirect: () => '/tools/sq'
          },
          {
            path: 'appointment',
            component: () =>
              import('@/views/tools/appointment/AppointmentLookup')
          },
          {
            path: 'sq',
            component: () => import('@/views/tools/sq/SqLookup')
          }
        ]
      }
    ]
  },

  /* 
    "/appointment" is a publicly accessible page for rebooking an NBN appointment.
    Users navigate here by clicking a link in a "missed your appointment" email.
  */
  {
    path: '/appointment',
    component: () => import('@/views/appointment/ArrangeAppointment')
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: '/' // Redirects unknown urls to root
  }
]
