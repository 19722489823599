<!-- 
  This template displays a modal error dialog, intended for displaying app-wide
  error messages. It is triggered by apiHelper when a request fails. This is done by
  apiHelper setting an error object in src/store/ui-state > state.error. When the 
  error object is not null, this error dialog will appear.

  Error object:

    - uiState.state.error.response (Object): Response object from apiHelper
    - uiState.state.error.headers (Object): Headers object from apiHelper
    - uiState.state.error.payload (Object): Payload object from apiHelper
    - uiState.state.error.transformationError (Object): If error is a coding error inside a data transformation and not an actual api error, this object is populated from apiHelper.

-->

<template>
  <q-dialog v-model="showDialog">
    <q-card v-if="!!error" class="error">
      <q-card-section class="row">
        <q-icon color="primary" name="warning_amber" size="60px" />

        <div class="content col">
          <div class="message text-weight-bold">{{ title }}</div>
          <div class="detail">
            <div v-if="error.transformationError">
              <p>
                <b>URL:</b> {{ error.response.method }} {{ error.response.url }}
              </p>
              <b>Stack:</b> {{ error.transformationError.stack }}
            </div>
            <div v-else>
              <code
                v-if="jsonError"
                class="q-mt-md q-mb-sm text-primary"
                style="background-color: var(--background-purple)"
              >
                <div
                  v-for="(item, idx) in Object.entries(jsonError)"
                  :key="idx"
                  class="q-mb-xs"
                >
                  <div>
                    <b>{{ item[0] }}:</b> {{ item[1] }}
                  </div>
                </div>
              </code>

              <q-toggle
                v-model="state.showRequest"
                label="Show request"
                color="deep-purple"
                class="q-mr-sm"
              />
              <code v-if="state.showRequest" class="q-mb-sm"
                ><b>{{ error.response.method }}</b> {{ error.response.url }}
                <div v-if="error.payload" class="q-mt-sm">
                  <div
                    v-for="(item, idx) in Object.entries(error.payload)"
                    :key="idx"
                    class="q-mb-xs"
                  >
                    <div>
                      <b>{{ item[0] }}:</b> {{ item[1] }}
                    </div>
                  </div>
                </div>
              </code>
              <q-toggle
                v-model="state.showHeaders"
                label="Show headers"
                color="deep-purple"
              />
              <code v-if="state.showHeaders">
                <div
                  v-for="(item, idx) in Object.entries(error.headers || [])"
                  :key="idx"
                  class="q-mb-xs"
                >
                  <b>{{ item[0] }}:</b> {{ item[1] }}
                </div>
                <div v-if="!Object.entries(error.headers || []).length">
                  [Empty]
                </div>
              </code>
            </div>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          v-if="!error.transformationError"
          unelevated
          label="Copy error"
          icon="content_copy"
          @click="() => copy()"
        />
        <q-btn
          unelevated
          label="CLOSE"
          color="primary"
          @click="() => (showDialog = false)"
          style="min-width: 100px"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { getError, setError } from '@/store/ui-state'
import { computed, reactive } from 'vue'
import { copyToClipboard, Notify } from 'quasar'

const error = computed(() => getError())
const showDialog = computed({
  get() {
    return !!getError()
  },
  set() {
    setError(null)
  }
})
const jsonError = computed(() => {
  if (typeof error.value.response.error === 'object') {
    return error.value.response.error
  }
  return null
})
const title = computed(() => {
  if (error.value.response.error === 'Failed to fetch') {
    return 'URL INACCESSIBLE (Failed to fetch)'
  }
  if (error.value.transformationError) {
    return 'DATA TRANSFORMATION ERROR'
  }
  if (jsonError.value) {
    return 'ERROR DETAILS'
  }
  return error.value.response.error || `${error.value.response.status} ERROR`
})
const state = reactive({
  showRequest: true,
  showHeaders: false
})

function copy() {
  // Builds a formatted json string of values to be copied to the clipboard
  const response = error.value.response
  const items = []
  addItem(
    'error',
    jsonError.value ? JSON.stringify(jsonError.value) : response.error,
    items
  )
  addItem('method', response.method, items)
  addItem('payload', JSON.stringify(error.value.payload), items)
  addItem('status', response.status, items)
  addItem('url', response.url, items)

  copyToClipboard(`{\n${items.join(`,\n`)}\n}`) // Add line breaks between items & wrap in '{}'
  Notify.create({
    type: 'positive',
    message: `<b>COPIED TO CLIPBOARD</b>`,
    html: true
  })
}

function addItem(key, value, items) {
  // Adds indented key/value text into items array, ready to be joined
  // and formatted into json text for copying to clipboard.
  if (value === undefined) {
    return
  }
  if (value[0] === '{') {
    // If value is JSON, don't wrap in quotes...
    items.push(`  "${key}": ${value}`)
  } else {
    items.push(`  "${key}": "${value}"`)
  }
}
</script>

<style scoped>
.error {
  min-width: 300px;
  max-width: 800px;
}
.content {
  margin: 1em 1em 0 1em;
  flex: 1;
}
.message {
  font-size: 16px;
}
.detail {
  margin-top: 0.5em;
  white-space: break-spaces;
}
:deep(code) {
  background-color: #f7f7f7;
  display: block;
  font-size: 12px;
  padding: 8px;
  max-width: 100%;
  overflow: auto;
  border-radius: 4px;
}
</style>
