export const joinAddressParts = (commaSeparatedParts, spaceSeparatedParts) => {
  /*
      Joins address parts into a string without any additional spacing or commas when
      values are empty. E.g.

      data:
        address1: "200 The Street"
        address2: ""
        suburb: "Forest Lodge"
        state: "NSW"
        postcode: "2037"

      joinAddressParts([ address1, address2 ], [ suburb, state, postcode ])

      ---> "200 The Street, Forest Lodge NSW 2037"

      PARAMS:
        commaSeparatedParts - Array of address parts to be comma separated
        spaceSeparatedParts - Array of subsequent address parts to be space separated
      ])
  */
  const commaSeparated = commaSeparatedParts.filter(p => !!p).join(', ')
  const spaceSeparated = spaceSeparatedParts.filter(p => !!p).join(' ')
  return [commaSeparated, spaceSeparated].filter(p => !!p).join(', ')
}
