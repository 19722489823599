/*
  Provides app-level state for global ui features and exposes necessary setters & getters.
*/

import { reactive } from 'vue'

const state = reactive({
  user: null, // Current logged in user
  users: [], // Array of all Cognito provisioning users (populated at start up)
  uiFlags: null, // Feature flags loaded at startup from api
  pageTitle: null, // Current page title, e.g. "Tasks" (as set by AppToolbar according to its navigation configuration)
  pageIcon: null, // Current page icon name (as set by AppToolbar according to its navigation configuration)
  showSpinner: null, // If true, a modal app spinner will appear (see actions > showSpinner() )
  confirm: null, // If set, a modal confirmation message box will appear. See actions > confirm() for details of how this object is set.
  error: null, // When populated with error information, an error dialog will be displayed.
  homeRoute: '' // Home has tabs which determine the page displayed (e.g. "/home/tasks"). This remembers that filter setting.
})

export const getUiFlags = () => state.uiFlags
export const setUiFlags = flags => {
  state.uiFlags = flags
}

export const getUser = () => state.user
export const setUser = user => {
  state.user = user
}

export const getUsers = () => state.users
export const setUsers = users => {
  state.users = users
}

export const getPageTitle = () => state.pageTitle
export const getPageIcon = () => state.pageIcon
export const setPageTitleAndIcon = (title, icon) => {
  state.pageTitle = title
  state.pageIcon = icon
}

export const isSpinner = () => state.showSpinner
export const showSpinner = () => {
  state.showSpinner = true
}
export const hideSpinner = () => {
  state.showSpinner = false
}

export const getError = () => state.error

export const getHomeRoute = () => state.homeRoute
export const setHomeRoute = () => {
  state.homeRoute = getCurrentPath(true)
}

export const setError = params => {
  /*
    When params below are set, an api error dialog will be displayed. If params are
    null, dialog will be hidden.

    - response (Object): Response object from apiHelper
    - headers (Object): Headers object from apiHelper
    - payload (Object): Payload object from apiHelper
    - transformationError (Object): If error is a coding error inside a data transformation and not an actual api error, this object is populated from apiHelper.
  */
  state.error = params
}

export const getConfirm = () => state.confirm
export const clearConfirm = () => {
  state.confirm = null
}
export const confirm = ({
  text,
  detail,
  detailHtml,
  okText,
  cancelText,
  icon,
  color,
  iconColor
}) => {
  /*
    Shows a confirmation dialog and returns a promise. Parameters:

    - text (string): Primary message text
    - detail (string): Optional secondary message text
    - detailHtml (string): Optional secondary message html
    - okText (string): Optional text for the confirm button (defaults to "OK")
    - cancelText (string): Optional cancel button text (defaults to "Cancel"). If set to null or empty, no cancel button is displayed.
    - icon (string): Optional icon name (from Material Icons) to use for the main icon. Otherwise a question mark icon will be used if the confirmation has confirm & cancel buttons, and otherwise an info icon will be used.
    - color (string): Optional color for the primary button
    - iconColor (string): Optional color for the icon
  */
  return new Promise(resolve => {
    state.confirm = {
      text,
      detail,
      detailHtml,
      icon,
      color,
      iconColor,
      okText,
      cancelText,
      onConfirm() {
        state.confirm = null
        resolve(true)
      },
      onCancel() {
        state.confirm = null
        resolve(false)
      }
    }
  })
}

function getCurrentPath(includeParams) {
  if (includeParams) {
    return location.href.substring(location.origin.length)
  }
  return location.pathname
}
